

















































































































































































































































import { RouterNames } from "@/router/routernames";
import dieta_visualizacionModule from "@/store/modules/dieta_visualizacion-module";
import pacienteModule from "@/store/modules/paciente-module";
import recomendacionModule from "@/store/modules/recomendacion-module";
import anotaciones_dietaModule from "@/store/modules/anotaciones_dieta-module";
import { UtilsString } from "@/utils/utils-string";
import { Component, Vue } from "vue-property-decorator";
import Tab from "./Tab.vue";
import Tabs from "./Tabs.vue";
@Component({
  components: { Tabs, Tab },
})
export default class ViewDieta extends Vue {
  public comidas: string[] = [
    "Desayuno",
    "Tentempié 1",
    "Comida",
    "Merienda",
    "Cena",
  ];

  public platos: string[] = ["Paella", "Carne", "Ensalada"];
  public id_cache = "";
  public fabAbierto = false;
  created() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id)) {
      this.id_cache = "dieta" + UtilsString.ValueOf(this.$route.query.id);
      console.log("this.has_dieta_cache()", this.has_dieta_cache());
      if (!this.has_dieta_cache()) {
        dieta_visualizacionModule
          .getDieta(Number.parseInt(UtilsString.ValueOf(this.$route.query.id)))
          .then((x) => {
            localStorage.setItem(this.id_cache, JSON.stringify(x));

            console.log(this.id_cache, x);
          });
      } else {
        dieta_visualizacionModule
          .getDietaCache(
            Number.parseInt(UtilsString.ValueOf(this.$route.query.id))
          )
          .then((x) => {
            localStorage.setItem(this.id_cache, JSON.stringify(x));

            console.log(this.id_cache, x);
          });
      }

      recomendacionModule.getrecomendaciondieta(
        Number.parseInt(UtilsString.ValueOf(this.$route.query.id))
      );
      anotaciones_dietaModule.getanotaciones_dieta(
        Number.parseInt(UtilsString.ValueOf(this.$route.query.id))
      );
    }
  }
  public get dieta() {
    if (this.has_dieta_cache()) {
      return JSON.parse(
        UtilsString.ValueOf(localStorage.getItem(this.id_cache))
      );
    }
    return dieta_visualizacionModule.dieta_visualizacions;
  }
  public get stilo() {
    let h = this.$vuetify.breakpoint.height - 160;
    return "max-height:" + h + "px;overflow: auto";
  }
  public get cantidades() {
    return !pacienteModule.paciente.ocultar_cantidades;
  }
  public get hayrecomendacionesdieta() {
    if (recomendacionModule.recomendacion_dieta === undefined) {
      return false;
    }
    if (recomendacionModule.recomendacion_dieta.recomendaciones === undefined) {
      return false;
    }
    if (recomendacionModule.recomendacion_dieta.recomendaciones.length === 0) {
      return false;
    }
    return true;
  }
  public get hayanotacionesdieta() {
    if (anotaciones_dietaModule.anotaciones_dieta === undefined) {
      return false;
    }
    if (
      UtilsString.IsNullOrWhiteSpace(
        anotaciones_dietaModule.anotaciones_dieta.anotaciones_html
      )
    ) {
      return false;
    }
    return true;
  }
  public has_dieta_cache() {
    var cache_dietas = localStorage.getItem(this.id_cache);
    return cache_dietas !== null;
  }
  public labeldia(index: number) {
    let arr = ["L", "M", "X", "J", "V", "S", "D"];
    return arr[index];
  }
  public labelComida(index: number) {
    let arr = [
      "Desayuno",
      "Tentempié 1",
      "Tentempié 2",
      "Comida",
      "Merienda 1",
      "Merienda 2",
      "Cena",
      "Recena 1",
      "Recena 2",
    ];
    return arr[index];
  }
  public moreinfo(plato: any) {
    this.$router.push({
      name: RouterNames.dieta_info,
      params: { data_plato_dieta: plato },
    });
  }

  public Recomendaciones() {
    this.$router.push({
      name: RouterNames.recomendaciones_dieta,
      params: { id: UtilsString.ValueOf(this.$route.query.id) },
    });
  }
  public Anotaciones() {
    this.$router.push({
      name: RouterNames.anotaciones_dieta,
      params: { id: UtilsString.ValueOf(this.$route.query.id) },
    });
  }
  public toggleFab() {
    this.fabAbierto = !this.fabAbierto;
  }
}
